import { directusClient } from "../../hooks/useDirectus";

async function getCards(cardIds) {
    let { data: cards } = await directusClient.getItems(`card`, {
        fields: [
            `*`,
            `image.data.asset_url`,
            `set.id`,
            `set.series.id`,
            `set.series.game.id`,
            `set.series.game.ref`,
        ],
        filter: { id: { in: cardIds.toString() } },
    });
    return cards;
}
async function getCard(cardId) {
    if (typeof cardId === `undefined`) {
        return Promise.resolve({ data: [] });
    }
    let { data: card } = await directusClient.get(`/item/card/${cardId}`, {
        fields: [
            `*`,
            `image.data.asset_url`,
            `set.id`,
            `set.series.id`,
            `set.series.game.id`,
            `set.series.game.ref`,
            `set.series.game.available_standard`,
            `set.series.game.available_foiled`,
            `set.series.game.available_reverse_foiled`,
            `set.series.game.available_signed`,
            `set.series.game.languages`,
        ],
    });
    return card;
}

export { getCards, getCard };
