import { createSlice } from "@reduxjs/toolkit";
import { getGames } from "../games/gamesClient";

function startLoading(state) {
    state.isLoading = true;
}

function loadingFailed(state, action) {
    state.isLoading = false;
    state.error = action.payload;
}

// createSlice and createReducer wrap your function with produce from the Immer library.
// This means you can write code that "mutates" the state inside the reducer, and Immer will safely return a correct immutably updated result.
const gamesSlice = createSlice({
    name: `games`,
    initialState: {
        gamesById: {},
        refreshInterval: 86400000 /* 24 hours in microseconds */,
        isLoading: false,
        updated: ``,
        error: null,
    },
    reducers: {
        getGamesStart: startLoading,
        getGamesSuccess(state, { payload }) {
            const { games, updated } = payload;
            games.forEach((game) => {
                state.gamesById[game.id] = game;
            });
            state.updated = updated;
            state.isLoading = false;
            state.error = null;
        },
        getGamesFailure: loadingFailed,
    },
});

export const { getGamesStart, getGamesSuccess, getGamesFailure } =
    gamesSlice.actions;

export default gamesSlice.reducer;

// Thunks
export const fetchGames =
    (filter = `active`) =>
    async (dispatch) => {
        try {
            dispatch(getGamesStart());
            const games = await getGames(filter);
            dispatch(getGamesSuccess({ games, updated: Date.now() }));
        } catch (err) {
            dispatch(getGamesFailure(err.toString()));
        }
    };
