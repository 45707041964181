import { directusClient } from "../../hooks/useDirectus";

async function getTrades(filter) {
    let { data: trades } = await directusClient.get(`/custom/trade`, {});
    return trades;
}

async function getTrade(tradeId) {
    if (typeof tradeId === `undefined`) {
        return Promise.resolve({ data: [] });
    }
    let { data: trade } = await directusClient.get(
        `/custom/trade/${tradeId}`,
        {}
    );
    return trade;
}

async function createTrade(tradeData, tradeItemData) {
    let { data: trade } = await directusClient.post(`custom/trade`, {
        tradeData,
        tradeItemData,
    });
    return trade;
}

async function updateTrade(tradeId, updates) {
    if (typeof tradeId === `undefined`) {
        return Promise.reject([]);
    }
    let { data: trade } = await directusClient.patch(
        `custom/trade/${tradeId}`,
        updates
    );
    return trade;
}

async function removeTrade(tradeId) {
    if (typeof tradeId === `undefined`) {
        return Promise.reject([]);
    }
    let { data: trade } = await directusClient.delete(
        `/custom/trade/${tradeId}`
    );
    return trade;
}

async function createTradeComment(tradeId, comment) {
    let { data: commentData } = await directusClient.post(
        `custom/trade/${tradeId}/comments`,
        { comment }
    );
    return commentData;
}

export {
    getTrades,
    getTrade,
    createTrade,
    updateTrade,
    removeTrade,
    createTradeComment,
};
