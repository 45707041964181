import PropTypes from "prop-types";
import { directusClient } from "../../hooks/useDirectus";

const listTableNames = {
    MyCollection: `my_collection`,
    WantInTrade: `want_in_trade`,
    OfferForTrade: `offered_for_trade`,
    Buying: `buying`,
    Selling: `selling`,
};

// TODO: Move title and urlTitle to language object
const emptyLists = {
    MyCollection: {
        title: `My collection`,
        urlTitle: `my-collection`,
        table: `my_collection`,
        items: [],
        updated: ``,
    },
    WantInTrade: {
        title: `Trades: Wanted`,
        urlTitle: `want-in-trade`,
        table: `want_in_trade`,
        items: [],
        updated: ``,
    },
    OfferForTrade: {
        title: `Trades: Offered`,
        urlTitle: `offered-for-trade`,
        table: `offered_for_trade`,
        items: [],
        updated: ``,
    },
    Buying: {
        title: `Sales: Wanted`,
        urlTitle: `buying`,
        table: `buying`,
        items: [],
        updated: ``,
    },
    Selling: {
        title: `Sales: Offered`,
        urlTitle: `selling`,
        table: `selling`,
        items: [],
        updated: ``,
    },
};

async function getLists(userId) {
    let ls = {};
    for (let listKey in listTableNames) {
        let list = await getList({ userId, listName: listKey });
        ls = { ...ls, [listKey]: list };
    }
    return ls;
}

async function getList(filters) {
    let { data: listItems, meta } = await getItems(filters);
    return {
        title: emptyLists[filters.listName].title,
        urlTitle: emptyLists[filters.listName].urlTitle,
        items: listItems,
        meta: meta,
    };
}

async function addItems({ listName, items }) {
    return await directusClient.createItems(listTableNames[listName], items);
}

async function addItem({
    listName,
    cardId,
    quantity,
    condition,
    language,
    foiled,
    reverseFoiled,
    signed,
}) {
    const itemBody = {
        status: `published`,
        card: cardId,
        quantity,
        condition,
        language,
        foiled,
        reverse_foiled: reverseFoiled,
        signed,
    };
    const { data: item } = await directusClient.createItem(
        listTableNames[listName],
        itemBody
    );
    return {
        itemId: item.id,
        cardId: item.card,
        quantity: item.quantity,
        condition: item.condition,
        language: item.language,
        foiled: item.foiled,
        reverseFoiled: item.reverse_foiled,
        signed: item.signed,
    };
}

async function removeItem({ listName, listItemId }) {
    let { data: item } = await directusClient.deleteItem(
        listTableNames[listName],
        listItemId
    );
    return item;
}

async function updateItem({
    listName,
    itemId,
    quantity,
    condition,
    language,
    foiled,
    reverseFoiled,
    signed,
}) {
    const itemBody = {
        quantity,
        condition,
        language,
        foiled,
        reverse_foiled: reverseFoiled,
        signed,
    };
    const { data: item } = await directusClient.updateItem(
        listTableNames[listName],
        itemId,
        itemBody
    );
    return {
        itemId: item.id,
        cardId: item.card,
        quantity: item.quantity,
        condition: item.condition,
        language: item.language,
        foiled: item.foiled,
        reverseFoiled: item.reverse_foiled,
        signed: item.signed,
    };
}

function getItems(filters) {
    // Currently all lists can be viewed publicly except My Collection
    const languageFilter = filters.l ? { language: { eq: filters.l } } : null;
    const gameUrlFilter = filters.gameUrl
        ? { "card.set.series.game.url_title": { eq: filters.gameUrl } }
        : null;
    const setUrlFilter = filters.setUrl
        ? { "card.set.url_title": { eq: filters.setUrl } }
        : null;
    const cardIdFilter = filters.cardId
        ? { card: { eq: filters.cardId } }
        : null;
    const filterByGame =
        filters.g || filters.gameRef
            ? {
                  "card.set.series.game.ref": {
                      eq: filters.g || filters.gameRef,
                  },
              }
            : null;
    const filterBySeries = filters.se
        ? { "card.set.series.id": { eq: filters.se } }
        : null;
    const filterBySet =
        filters.s || filters.setId
            ? { "card.set.id": { eq: filters.s || filters.setId } }
            : null;
    const userIdFilter =
        filters.u || filters.userId
            ? { created_by: { eq: filters.u || filters.userId } }
            : null;
    const convertedFilters = {
        ...cardIdFilter,
        ...gameUrlFilter,
        ...languageFilter,
        ...(filterBySet || filterBySeries || filterByGame),
        ...setUrlFilter,
        ...userIdFilter,
    };

    const defaultFilters = {
        status: {
            eq: `published`,
        },
        "card.status": {
            eq: `published`,
        },
        "card.set.status": {
            eq: `published`,
        },
        "card.set.series.status": {
            eq: `published`,
        },
        "card.set.series.game.status": {
            eq: `published`,
        },
    };

    return directusClient.get(`/items/${listTableNames[filters.listName]}`, {
        meta: `filter_count,offset,page,page_count,links,result_count`,
        fields: [
            `id`,
            `created_by.id`,
            `quantity`,
            `condition`,
            `language`,
            `foiled`,
            `reverse_foiled`,
            `signed`,
            `card.id`,
            `card.name`,
            `card.subtitle`,
            `card.url_title`,
            `card.type`,
            `card.number`,
            `card.image_url`,
            `card.rarity.id`,
            `card.available_standard`,
            `card.available_foiled`,
            `card.available_reverse_foiled`,
            `card.available_signed`,
            `card.languages_override`,
            `card.set.id`,
            `card.set.name`,
            `card.set.url_title`,
            `card.set.release_date`,
            `card.set.series.id`,
            `card.set.series.name`,
            `card.set.series.url_title`,
            `card.set.series.game.*`,
            `card.image.data.asset_url`,
        ],
        filter: { ...defaultFilters, ...convertedFilters },
        //sort: "card.set.series.game.name",
        page: filters.page || 1,
        limit: filters.limit || 25,
    });
}

addItems.propTypes = {
    listName: PropTypes.string.isRequired,
    items: PropTypes.array.isRequired,
};

export {
    emptyLists,
    getLists,
    getList,
    getItems,
    addItems,
    addItem,
    removeItem,
    updateItem,
};
