import "./src/css/style.css";
import "./src/css/spinner.css";

import { WrapWithProviders } from "./src/app/WrapWithProviders";

export const wrapRootElement = WrapWithProviders;

export const onClientEntry = () => {};

/*
 * https://www.gatsbyjs.com/docs/browser-apis/#onServiceWorkerUpdateReady
 * TODO: Replace with button/option for user to update app
 */
export const onServiceWorkerUpdateReady = () => window.location.reload();
