import * as React from "react";
import { useAuth } from "./authContext";
import * as listItemClient from "../utils/list-items-client";

const ListItemStateContext = React.createContext({});
const ListItemDispatchContext = React.createContext({});

function listReducer(lists, action) {
    switch (action.type) {
        case `add`: {
            lists[action.list].items = [
                ...lists[action.list].items,
                action.listItem,
            ];
            return lists;
        }
        case `remove`: {
            lists[action.list].items = lists[action.list].items.filter(
                (li) => li.id !== action.id
            );
            return lists;
        }
        case `update`: {
            lists[action.list].items = lists[action.list].items.map((li) => {
                if (li.id === action.listItem.id) {
                    return { ...li, ...action.listItem };
                }
                return li;
            });
            return lists;
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`);
        }
    }
}

function ListItemProvider({ children }) {
    const { data } = useAuth();
    const [state, dispatch] = React.useReducer(listReducer, data.lists);
    return (
        <ListItemStateContext.Provider value={state}>
            <ListItemDispatchContext.Provider value={dispatch}>
                {children}
            </ListItemDispatchContext.Provider>
        </ListItemStateContext.Provider>
    );
}

function removeListItem(dispatch, list, id) {
    return listItemClient.remove(list, id).then((data) => {
        dispatch({ type: `remove`, list: list, id });
        return data;
    });
}

function addListItem(dispatch, list, listItemData) {
    return listItemClient.create(list, listItemData).then((data) => {
        dispatch({ type: `add`, list: list, listItem: data.data });
        return data;
    });
}

function updateListItem(dispatch, list, listItemId, updates) {
    return listItemClient.update(list, listItemId, updates).then((data) => {
        dispatch({ type: `update`, list: list, listItem: data.listItem });
        return data;
    });
}

function useListItemDispatch() {
    const context = React.useContext(ListItemDispatchContext);
    if (context === undefined) {
        throw new Error(
            `useListItemDispatch must be used within a ListItemProvider`
        );
    }
    return context;
}

function useListItemState() {
    const context = React.useContext(ListItemStateContext);
    if (context === undefined) {
        throw new Error(
            `useListItemState must be used within a ListItemProvider`
        );
    }
    return context;
}

function useSingleListItemState({ cardId, list }) {
    let items = {}; //listItemClient.emptyLists();
    const listItems = useListItemState();
    if (!listItems) {
        throw new Error(
            `useListItemState must be used within a ListItemProvider`
        );
    }
    // TODO: Verify that it's only looking at the current user's list
    listItemClient.listsArray().forEach((listsKey) => {
        let item = listItems[listsKey].items.find((li) => {
            return li.card === cardId;
        });
        if (typeof item !== `undefined`) {
            items[listsKey] = item;
        }
    });

    return items;
}

export {
    ListItemProvider,
    useListItemDispatch,
    useListItemState,
    useSingleListItemState,
    removeListItem,
    addListItem,
    updateListItem,
};
