import * as React from "react";
// TODO: Remove react-async
import { useAsync } from "react-async";
import { bootstrapAppData } from "../utils/bootstrap";
import * as authClient from "../utils/auth-client";
import { emptyLists } from "../utils/list-items-client";
import { useDispatch } from "react-redux";
import { resetApp } from "../app/rootReducer";

const defaultState =
    {}; /* Fix for "Cannot destructure property" during build */
const AuthContext = React.createContext(defaultState);

function AuthProvider(props) {
    const dispatch = useDispatch();
    const { data = { user: null, lists: emptyLists() }, reload } = useAsync({
        promiseFn: bootstrapAppData,
    });

    const login = (form) => {
        dispatch(resetApp());
        return authClient.login(form).then(() => {
            reload();
        });
    };
    const register = (form) => authClient.register(form);
    const logout = () =>
        authClient.logout().then(() => {
            data.user = null;
            reload();
            dispatch(resetApp({}));
        });
    const isLoggedIn = () => authClient.isLoggedIn();

    return (
        <AuthContext.Provider
            value={{ data, login, logout, register, isLoggedIn }}
            {...props}
        />
    );
}

function useAuth() {
    const context = React.useContext(AuthContext);
    if (context === undefined) {
        throw new Error(`useAuth must be used within a AuthProvider`);
    }
    return context;
}

export { AuthProvider, useAuth };
