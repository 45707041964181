import { createSlice } from "@reduxjs/toolkit";
import { getUser } from "./client";
import { updateUser } from "../account/client";

function startLoading(state) {
    state.isLoading = true;
}
function loadingFailed(state, action) {
    state.isLoading = false;
    state.error = action.payload;
}
function basicUpdate(state, { payload }) {
    const { user, updated } = payload;
    state.usersById[user.id] = user;
    state.usersById[user.id].updated = updated;
    state.isLoading = false;
    state.error = null;
}

// createSlice and createReducer wrap your function with produce from the Immer library.
// This means you can write code that "mutates" the state inside the reducer, and Immer will safely return a correct immutably updated result.
const userSlice = createSlice({
    name: `users`,
    initialState: {
        myID: null,
        usersById: {},
        refreshInterval: 86400000000 /* 1 day in microseconds */,
        isLoading: false,
        updated: ``,
        error: null,
    },
    reducers: {
        getUserStart: startLoading,
        getUserSuccess: basicUpdate,
        getUserFailure: loadingFailed,
        updateUserStart: startLoading,
        updateUserSuccess: basicUpdate,
        updateUserFailure: loadingFailed,
    },
});

export const {
    getUserStart,
    getUserSuccess,
    getUserFailure,
    updateUserStart,
    updateUserSuccess,
    updateUserFailure,
} = userSlice.actions;

export default userSlice.reducer;

// Thunks
export const fetchUser = (userId) => async (dispatch, getState) => {
    const { isLoading, usersById, refreshInterval } = getState().users;
    // Only perform a new API fetch on outdated data
    if (
        isLoading ||
        (usersById[userId] &&
            usersById[userId].updated + refreshInterval > Date.now())
    ) {
        return;
    }
    try {
        dispatch(getUserStart());
        const user = await getUser(userId);
        if (user.length === 0) {
            dispatch(getUserFailure(`User not found or inaccessible`));
            return;
        }
        dispatch(getUserSuccess({ user, updated: Date.now() }));
    } catch (err) {
        dispatch(getUserFailure(err.toString()));
    }
};
export const updateUserProfile = (userId, updates) => async (dispatch) => {
    try {
        dispatch(updateUserStart());
        const user = await updateUser(userId, updates);
        if (user.error) {
            dispatch(updateUserFailure(user.error.toString()));
            return;
        }
        dispatch(updateUserSuccess({ user, updated: Date.now() }));
    } catch (err) {
        dispatch(updateUserFailure(err.toString()));
    }
};
