import { createSlice } from "@reduxjs/toolkit";

// createSlice and createReducer wrap your function with produce from the Immer library.
// This means you can write code that "mutates" the state inside the reducer, and Immer will safely return a correct immutably updated result.
const messagesSlice = createSlice({
    name: `messages`,
    initialState: {
        lowPriority: [],
        mediumPriority: [],
        highPriority: [],
    },
    reducers: {
        /* Using FIFO (first-in-first-out) processing of messages */
        addMessage(state, { payload }) {
            const { id, text, priority } = payload;
            switch (priority) {
                case `high`:
                    state.highPriority.push({
                        id: id,
                        text: text,
                        action: null,
                    });
                    break;
                case `med`:
                    state.mediumPriority.push({
                        id: id,
                        text: text,
                        action: null,
                    });
                    break;
                default:
                    state.lowPriority.push({
                        id: id,
                        text: text,
                        action: null,
                    });
                    break;
            }
        },
        removeMessage(state, { payload }) {
            const { id, priority } = payload;
            let messageList = ``;
            switch (priority) {
                case `high`:
                    messageList = `highPriority`;
                    break;
                case `med`:
                    messageList = `mediumPriority`;
                    break;
                default:
                    messageList = `lowPriority`;
                    break;
            }
            state[messageList] = state[messageList].filter(
                (li) => li.id !== id
            );
        },
    },
});

export const { addMessage, removeMessage } = messagesSlice.actions;

export default messagesSlice.reducer;

// Thunks
