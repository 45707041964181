import { directusClient } from "../../hooks/useDirectus";
import PropTypes from "prop-types";

// Zero returns own user data
const getUser = (userId = 0) => {
    // Custom endpoint that combines directus_users and user_details
    return directusClient.get(`custom/users/${userId}`, {});
};

getUser.propTypes = {
    userId: PropTypes.number,
};

export { getUser };
