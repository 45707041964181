const typography = {
    fontFamily: [`"Montserrat"`, `Arial`, `sans-serif`].join(`,`),
};

const overrides = {
    MuiButtonBase: {
        root: {
            margin: undefined,
        },
    },
};

export { typography, overrides };
