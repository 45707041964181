/*
 * Top-level UI context for data that needs to be managed/accessed from many components at different nesting levels
 */
import * as React from "react";

const getCurrency = () => {
    if (typeof window === `undefined`) {
        return `USD`;
    }
    return window.localStorage.getItem(`currencyCode`) || `USD`;
};

const initialState = {
    cartOpen: false,
    displayCurrency: getCurrency(),
    userLocale: Intl.DateTimeFormat().resolvedOptions().locale || `en-US`,
    loginRegisterTab: 0,
    loginRegisterOpen: false,
    loginRegisterRedirect: ``,
};

const UIStateContext = React.createContext(initialState);
const UIDispatchContext = React.createContext({});

const UIProvider = ({ children }) => {
    const [state, dispatch] = React.useReducer(uiReducer, initialState);
    return (
        <UIStateContext.Provider value={state}>
            <UIDispatchContext.Provider value={dispatch}>
                {children}
            </UIDispatchContext.Provider>
        </UIStateContext.Provider>
    );
};

/* REDUCERS */
const uiReducer = (ui, action) => {
    switch (action.type) {
        case `SHOW_LOGIN`: {
            return {
                ...ui,
                loginRegisterTab: 0,
                loginRegisterOpen: true,
                loginRegisterRedirect: action.payload,
            };
        }
        case `SHOW_REGISTER`: {
            return {
                ...ui,
                loginRegisterTab: 1,
                loginRegisterOpen: true,
                loginRegisterRedirect: action.payload,
            };
        }
        case `SHOW_LOGIN_REGISTER_TAB_0`: {
            return {
                ...ui,
                loginRegisterTab: 0,
            };
        }
        case `SHOW_LOGIN_REGISTER_TAB_1`: {
            return {
                ...ui,
                loginRegisterTab: 1,
            };
        }
        case `HIDE_LOGIN_REGISTER`: {
            return {
                ...ui,
                loginRegisterOpen: false,
            };
        }
        case `SHOW_CART`: {
            return {
                ...ui,
                cartOpen: true,
            };
        }
        case `HIDE_CART`: {
            return {
                ...ui,
                cartOpen: false,
            };
        }
        case `SET_DISPLAY_CURRENCY`: {
            window.localStorage.setItem(`currencyCode`, action.payload);
            return {
                ...ui,
                displayCurrency: action.payload,
            };
        }
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
};

/* CUSTOM HOOKS */
const useUIState = () => {
    const context = React.useContext(UIStateContext);
    if (context === undefined) {
        throw new Error(`useUIState must be used within an UIProvider`);
    }
    return context;
};
const useUIDispatch = () => {
    const context = React.useContext(UIDispatchContext);
    if (context === undefined) {
        throw new Error(`useUIDispatch must be used within an UIProvider`);
    }
    return context;
};

/* ACTIONS */

export { UIProvider, useUIDispatch, useUIState };
