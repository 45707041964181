import { directusClient } from "../../hooks/useDirectus";
import PropTypes from "prop-types";

const directusUserCols = [
    `first_name`,
    `last_name`,
    `company`,
    `email`,
    `password`,
];

/* Reset URL: Provide a custom reset url which the link in the Email will lead to. The reset token will be passed as a parameter. */
function requestPasswordReset({
    email,
    resetUrl = `${process.env.GATSBY_SITE_URL}/reset-password`,
}) {
    return directusClient.post(`/auth/password/request`, {
        email: email,
        reset_url: resetUrl,
    });
}
function resetPassword({ token, password }) {
    return directusClient.post(`/auth/password/reset`, {
        token: token,
        password: password,
    });
}

const updateUser = async ({ userId, updates }) => {
    if (Object.keys(updates).length === 0) {
        return {};
    }

    let userUpdates = {};
    let userDetailsUpdates = {};
    const keys = Object.keys(updates);
    keys.forEach((key) => {
        directusUserCols.includes(key)
            ? (userUpdates = { ...userUpdates, [key]: updates[key] })
            : (userDetailsUpdates = {
                  ...userDetailsUpdates,
                  [key]: updates[key],
              });
    });

    const { data: user } =
        Object.keys(userUpdates).length > 0
            ? await directusClient.patch(`users/${userId}`, userUpdates)
            : {};
    const userDetails = await updateUserDetails(userId, userDetailsUpdates);

    return { ...user, ...userDetails };
};
const updateUserDetails = async (userId, updates) => {
    if (Object.keys(updates).length === 0) {
        return {};
    }
    let { data: user } = await directusClient.patch(
        `items/user_details/${userId}`,
        updates
    );
    return user;
};

const getUserPreferences = (userId) => {
    if (!userId || typeof userId === `undefined`) {
        return Promise.resolve({ data: [] });
    }
    return directusClient
        .getItems(`user_preferences`, {
            filter: { id: { eq: userId } },
        })
        .then((res) => res.data[0]);
};
const getUserSubscriptions = (userId) => {
    if (!userId || typeof userId === `undefined`) {
        return Promise.resolve({ data: [] });
    }
    return directusClient
        .getItems(`user_subscriptions`, {
            filter: { id: { eq: userId } },
        })
        .then((res) => res.data[0]);
};

const updateUserPreferences = ({ userId, prefs }) => {
    return directusClient.updateItem(`user_preferences`, userId, prefs);
};

const updateUserSubscriptions = ({ userId, subs }) => {
    return directusClient.updateItem(`user_subscriptions`, userId, subs);
};

requestPasswordReset.propTypes = {
    email: PropTypes.string.isRequired,
    resetUrl: PropTypes.string,
};
resetPassword.propTypes = {
    token: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
};
getUserPreferences.propTypes = {
    userId: PropTypes.number.isRequired,
};
getUserSubscriptions.propTypes = {
    userId: PropTypes.number.isRequired,
};
updateUserPreferences.propTypes = {
    data: PropTypes.shape({
        userId: PropTypes.number.isRequired,
        prefs: PropTypes.object.isRequired,
    }).isRequired,
};
updateUserSubscriptions.propTypes = {
    data: PropTypes.shape({
        userId: PropTypes.number.isRequired,
        subs: PropTypes.object.isRequired,
    }).isRequired,
};

export {
    requestPasswordReset,
    resetPassword,
    updateUser,
    getUserPreferences,
    getUserSubscriptions,
    updateUserPreferences,
    updateUserSubscriptions,
};
