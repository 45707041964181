import PropTypes from "prop-types";
import { cardStyle } from "../../utils/card-style";

/*
 Card SKU: C{game-ref}{card-id}{condition-id}{iso2-language-code}{style-letter[S,F,R]}{signed[0,1]}
 */

const skuType = {
    C: { name: `card` },
};

const createCardSku = (
    gameRef,
    cardId,
    languageCode,
    conditionId,
    styleRef,
    signed
) => {
    const skuCode =
        `C` +
        gameRef +
        cardId.toString().padStart(7, `0`) +
        languageCode.toUpperCase() +
        conditionId +
        cardStyle[styleRef].letter +
        Number(signed);
    if (skuCode.length !== 16) return false;
    return skuCode;
};

const splitSku = (sku) => {
    return {
        type: sku.sub(0, 1),
        gameRef: sku.substring(1, 4),
        cardId: Number(sku.substring(4, 11)),
        language: sku.substring(11, 13),
        conditionId: Number(sku.substring(13, 14)),
        style: sku.substring(14, 15),
        signed: !!Number(sku.substring(15)),
    };
};

const createSkuFilter = ({
    productType = `C`,
    gameRef,
    cardId,
    languageCode,
    conditionId,
    styleRef,
    signed,
}) => {
    const filters = {};
    if (gameRef) {
        filters.sku_code_start = productType + gameRef;
        if (cardId) {
            filters.sku_code_start += cardId.toString().padStart(7, `0`);
        }
    }
    if (signed) {
        filters.sku_end = Number(signed);
    }
    return filters;
};

createCardSku.propTypes = {
    gameRef: PropTypes.string.isRequired,
    cardId: PropTypes.number.isRequired,
    languageCode: PropTypes.string.isRequired,
    conditionId: PropTypes.number.isRequired,
    styleRef: PropTypes.oneOf([`S`, `F`, `R`]),
    signed: PropTypes.bool.isRequired,
};

export { createCardSku, createSkuFilter, skuType, splitSku };
