import * as React from "react";
import { AuthProvider } from "./authContext";
import { ListItemProvider } from "./listItemContext";
import { WindowDimensionsProvider } from "./windowDimensionsContext";
import { CommerceLayerProvider } from "./CommerceLayerContext";
import { endpoint, useCommerceLayerAuth } from "../hooks/useCommerceLayer";

export function AppProviders({ children }) {
    const CLAuth = useCommerceLayerAuth();
    const accessToken = CLAuth.data && CLAuth.data.accessToken;
    return (
        <AuthProvider>
            <CommerceLayerProvider
                accessToken={accessToken}
                endpoint={endpoint}
            >
                <WindowDimensionsProvider>
                    <ListItemProvider>{children}</ListItemProvider>
                </WindowDimensionsProvider>
            </CommerceLayerProvider>
        </AuthProvider>
    );
}
