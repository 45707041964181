const cardStyle = {
    standard: { name: `Standard`, letter: `S` },
    foiled: { name: `Foiled`, letter: `F` },
    "reverse-foiled": { name: `Reverse foiled`, letter: `R` },
};

const cardStyleByLetter = {
    S: `standard`,
    F: `foiled`,
    R: `reverse-foiled`,
};

export { cardStyle, cardStyleByLetter };
