import { getUser } from "./auth-client";
import { emptyLists } from "./list-items-client";

export async function bootstrapAppData() {
    const user = await getUser();
    if (!user) {
        return { user: null, lists: emptyLists() };
    }
    let lists = {};

    return {
        user,
        lists,
    };
}
