import { createSlice } from "@reduxjs/toolkit";
import {
    emptyLists,
    getLists,
    getList,
    addItem,
    removeItem,
    updateItem,
} from "./listsClient";
import { addMessage } from "../alerts/messagesSlice";
import { v1 as uuidv1 } from "uuid";

function startLoading(state) {
    state.isLoading = true;
}

function loadingFailed(state, action) {
    state.isLoading = false;
    state.error = action.payload;
}

function updateItems(state, listName, listItems) {
    state[listName].items = [];
    listItems.forEach((item) => {
        // Store card specific details separately
        state.cardsById[item.card.id] = item.card;
        // A list lookup so we can see if a card is already in a list
        item.card.id in state.listLookupByCard
            ? state.listLookupByCard[item.card.id].push(listName)
            : (state.listLookupByCard[item.card.id] = [listName]);
        // Add the items state with a pointer to the stored card
        const newItem = {
            itemId: item.id,
            cardId: item.card.id,
            quantity: item.quantity,
            condition: item.condition && item.condition.id,
            language: item.language,
            foiled: item.foiled,
            reverseFoiled: item.reverse_foiled,
            signed: item.signed,
        };
        state[listName].items.push(newItem);
    });
}

// createSlice and createReducer wrap your function with produce from the Immer library.
// This means you can write code that "mutates" the state inside the reducer, and Immer will safely return a correct immutably updated result.
const listsSlice = createSlice({
    name: `lists`,
    initialState: {
        listNames: Object.keys(emptyLists),
        ...emptyLists,
        cardsById: {},
        listLookupByCard: {},
        refreshInterval: 300000 /* in microseconds */,
        isLoading: false,
        updated: 0,
        error: null,
    },
    reducers: {
        getListsStart: startLoading,
        getListStart: startLoading,
        addListItemStart: startLoading,
        updateListItemStart: startLoading,
        removeListItemStart: startLoading,
        getListsSuccess(state, { payload }) {
            const { lists, updated } = payload;
            Object.entries(lists).forEach(([listName, listData]) => {
                updateItems(state, listName, listData.items);
                state[listName].updated = updated;
            });
            state.updated = updated;
            state.isLoading = false;
            state.error = null;
        },
        getListSuccess(state, { payload }) {
            const { list, listName, updated } = payload;
            updateItems(state, listName, list.items);
            state[listName].updated = updated;
            state.isLoading = false;
            state.error = null;
        },
        addListItemSuccess(state, { payload }) {
            const { item, listName, updated } = payload;
            // Point list item to the stored card
            state[listName].items.push(item);
            state[listName].updated = updated;
            item.cardId in state.listLookupByCard
                ? state.listLookupByCard[item.cardId].push(listName)
                : (state.listLookupByCard[item.cardId] = [listName]);
            state.isLoading = false;
            state.error = null;
        },
        updateListItemSuccess(state, { payload }) {
            const { item, listName, updated } = payload;
            // Point list item to the stored card
            state[listName].items = state[listName].items.filter(
                (li) => li.itemId !== item.itemId
            );
            state[listName].items.push(item);
            state[listName].updated = updated;
            item.cardId in state.listLookupByCard
                ? state.listLookupByCard[item.cardId].push(listName)
                : (state.listLookupByCard[item.cardId] = [listName]);
            state.isLoading = false;
            state.error = null;
        },
        removeListItemSuccess(state, { payload }) {
            const { listName, cardId, itemId, updated } = payload;
            state[listName].items = state[listName].items.filter(
                (li) => li.itemId !== itemId
            );
            state[listName].updated = updated;
            state.listLookupByCard[cardId] = state.listLookupByCard[
                cardId
            ].filter((val) => val !== listName);
            state.isLoading = false;
            state.error = null;
        },
        // An invalidated list will have to be fetched again
        invalidateList(state, { payload }) {
            const { listName } = payload;
            state[listName] = undefined;
        },
        // Reset the whole slice
        invalidateLists(state) {
            state = undefined;
        },
        getListsFailure: loadingFailed,
        getListFailure: loadingFailed,
        addListItemFailure: loadingFailed,
        updateListItemFailure: loadingFailed,
        removeListItemFailure: loadingFailed,
    },
});

export const {
    getListsStart,
    getListStart,
    addListItemStart,
    updateListItemStart,
    removeListItemStart,
    getListsSuccess,
    getListSuccess,
    addListItemSuccess,
    updateListItemSuccess,
    removeListItemSuccess,
    getListsFailure,
    getListFailure,
    addListItemFailure,
    updateListItemFailure,
    removeListItemFailure,
    invalidateList,
    invalidateLists,
} = listsSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.lists.value)`
//export const itemCount = state => state.lists.value;

export default listsSlice.reducer;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(fetchLists(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched

export const fetchLists =
    (userId, listNames = []) =>
    async (dispatch, getState) => {
        const { lists } = getState();
        // Only perform a new API fetch on outdated data
        if (
            lists.isLoading ||
            !userId ||
            lists.updated + lists.refreshInterval > Date.now()
        ) {
            return;
        }
        try {
            dispatch(getListsStart());
            const fetchedLists = await getLists(userId);
            dispatch(
                getListsSuccess({ lists: fetchedLists, updated: Date.now() })
            );
        } catch (err) {
            dispatch(getListsFailure(err.toString()));
        }
    };
export const fetchList = (userId, listName) => async (dispatch, getState) => {
    const { lists } = getState();
    // Only perform a new API fetch on outdated data
    if (
        lists.isLoading ||
        !userId ||
        lists[listName].updated + lists[listName].refreshInterval > Date.now()
    ) {
        return;
    }
    try {
        dispatch(getListStart());
        const list = await getList(``, userId, listName);
        dispatch(
            getListSuccess({ list, listName: listName, updated: Date.now() })
        );
    } catch (err) {
        dispatch(getListFailure(err.toString()));
    }
};
export const addListItem = (listName, data) => async (dispatch) => {
    try {
        dispatch(addListItemStart());
        const item = await addItem(listName, data);
        dispatch(
            addMessage({ id: uuidv1(), text: `Card added`, priority: `low` })
        );
        dispatch(
            addListItemSuccess({
                item,
                listName: listName,
                updated: Date.now(),
            })
        );
    } catch (err) {
        dispatch(addListItemFailure(err.toString()));
    }
};
export const updateListItem =
    (listName, itemId, updates) => async (dispatch) => {
        try {
            dispatch(updateListItemStart());
            const item = await updateItem(listName, itemId, updates);
            dispatch(
                addMessage({
                    id: uuidv1(),
                    text: `Changes saved`,
                    priority: `low`,
                })
            );
            dispatch(
                updateListItemSuccess({
                    item,
                    listName: listName,
                    updated: Date.now(),
                })
            );
        } catch (err) {
            dispatch(updateListItemFailure(err.toString()));
        }
    };
export const removeListItem =
    (listName, itemId, cardId) => async (dispatch) => {
        try {
            dispatch(removeListItemStart());
            const item = await removeItem({ listName, itemId });
            dispatch(
                addMessage({
                    id: uuidv1(),
                    text: `Card removed`,
                    priority: `low`,
                })
            );
            dispatch(
                removeListItemSuccess({
                    item,
                    listName,
                    itemId,
                    cardId,
                    updated: Date.now(),
                })
            );
        } catch (err) {
            dispatch(removeListItemFailure(err.toString()));
        }
    };
